// constants
import { ProductActionTypes } from './constants';
import Product from './model';

export interface ProductActionType {
    type:
        | ProductActionTypes.API_RESPONSE_SUCCESS
        | ProductActionTypes.API_RESPONSE_ERROR
        | ProductActionTypes.GET_PRODUCTS
        | ProductActionTypes.UPDATE_PRODUCT
    payload: {} | string;
}

// common success
export const productsApiResponseSuccess = (actionType: string, data: Product | Product[] | {} | []): ProductActionType => ({
    type: ProductActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const productsApiResponseError = (actionType: string, error: string): ProductActionType => ({
    type: ProductActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getProducts = (): ProductActionType => ({
    type: ProductActionTypes.GET_PRODUCTS,
    payload: {},
});

export const updateProduct = (product: Product): ProductActionType => ({
    type: ProductActionTypes.UPDATE_PRODUCT,
    payload: product,
});
