// constants
import { ClubActionTypes } from './constants';
import Club from './model';

export interface ClubActionType {
    type:
        | ClubActionTypes.API_RESPONSE_SUCCESS
        | ClubActionTypes.API_RESPONSE_ERROR
        | ClubActionTypes.GET_CLUBS
        | ClubActionTypes.CREATE_CLUB
        | ClubActionTypes.UPDATE_CLUB
        | ClubActionTypes.RENEW_CLUB
    payload: {} | string;
}

// common success
export const clubsApiResponseSuccess = (actionType: string, data: Club | Club[] | {} | []): ClubActionType => ({
    type: ClubActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const clubsApiResponseError = (actionType: string, error: string): ClubActionType => ({
    type: ClubActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getClubs = (): ClubActionType => ({
    type: ClubActionTypes.GET_CLUBS,
    payload: {},
});

export const createClub = (club: Club): ClubActionType => ({
    type: ClubActionTypes.CREATE_CLUB,
    payload: club,
});

export const updateClub = (club: Club): ClubActionType => ({
    type: ClubActionTypes.UPDATE_CLUB,
    payload: club,
});

export const renewClub = (club: Club): ClubActionType => ({
    type: ClubActionTypes.RENEW_CLUB,
    payload: club,
});
