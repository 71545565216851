export const dateToMysqlFormat = (d: Date) => {
    return new Date(d.getTime() - d.getTimezoneOffset() * 60 * 1000).toISOString().split('T')[0];
}

export const datetimeToMysqlFormat = (d: Date) => {
    const str = new Date(d.getTime() - d.getTimezoneOffset() * 60 * 1000).toISOString().replace('Z', '').split('T');
    return `${str[0]} ${str[1].split('.')[0]}`;
}

export const mysqlDateStrToDate = (dateStr: string) => {
    return new Date(dateStr.replace( /(\d{4})-(\d{2})-(\d{2})/, "$1/$2/$3"));
}

export const mysqlDatetimeStrToDate = (datetimeStr: string) => {
    const t: number[] = datetimeStr.split(/[- :]/).map((item: any) => Number(item));
    return new Date(t[0], t[1] - 1, t[2], t[3] || 0, t[4] || 0, t[5] || 0);
}

export const hasDST = (date = new Date()) => {
    const january = new Date(date.getFullYear(), 0, 1).getTimezoneOffset();
    const july = new Date(date.getFullYear(), 6, 1).getTimezoneOffset();
  
    return Math.max(january, july) !== date.getTimezoneOffset();
}

export const addHours = (date: Date, hours: number): Date => {
    date.setTime(date.getTime() + (hours * 3600000));
    return date;
}