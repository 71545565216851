// constants
import { ConstantActionTypes } from './constants';
import Constant from './model';

export interface ConstantActionType {
    type:
        | ConstantActionTypes.API_RESPONSE_SUCCESS
        | ConstantActionTypes.API_RESPONSE_ERROR
        | ConstantActionTypes.GET_CONSTANTS
        | ConstantActionTypes.UPDATE_CONSTANT
    payload: {} | string;
}

// common success
export const constantsApiResponseSuccess = (actionType: string, data: Constant | Constant[] | {} | []): ConstantActionType => ({
    type: ConstantActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const constantsApiResponseError = (actionType: string, error: string): ConstantActionType => ({
    type: ConstantActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getConstants = (): ConstantActionType => ({
    type: ConstantActionTypes.GET_CONSTANTS,
    payload: {},
});

export const updateConstant = (constant: Constant): ConstantActionType => ({
    type: ConstantActionTypes.UPDATE_CONSTANT,
    payload: constant,
});
