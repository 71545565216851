// constants
import { ProductActionTypes } from './constants';
import Product from './model';

const INIT_STATE = {
    products: [],
    loading: false,
    requestStatus: 0,
};

interface ProductActionType {
    type:
        | ProductActionTypes.API_RESPONSE_SUCCESS
        | ProductActionTypes.API_RESPONSE_ERROR
        | ProductActionTypes.GET_PRODUCTS
        | ProductActionTypes.UPDATE_PRODUCT
    payload: {
        actionType?: string;
        data?: Product | {};
        error?: string;
    };
}

interface State {
    products?: Product[] | {};
    loading?: boolean;
    requestStatus?: number;
}

const ProductReducer = (state: State = INIT_STATE, action: ProductActionType): any => {
    switch (action.type) {
        case ProductActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case ProductActionTypes.GET_PRODUCTS: {
                    return {
                        ...state,
                        products: action.payload.data,
                        loading: false,
                    };
                }
                case ProductActionTypes.UPDATE_PRODUCT: {
                    return {
                        ...state,
                        loading: false,
                        requestStatus: 1,
                    };
                }
                default:
                    return { ...state };
            }

        case ProductActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                default:
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                        requestStatus: -1,
                    };
            }
        case ProductActionTypes.GET_PRODUCTS:
            return { ...state, loading: true, requestStatus: 0};
        case ProductActionTypes.UPDATE_PRODUCT:
            return { ...state, loading: true, requestStatus: 0};
        default:
            return { ...state };
    }
};

export default ProductReducer;
