// constants
import { FilterType } from '../../helpers/api/apiCore';
import { LeagueActionTypes } from './constants';
import League from './model';

export interface LeagueActionType {
    type:
        | LeagueActionTypes.API_RESPONSE_SUCCESS
        | LeagueActionTypes.API_RESPONSE_ERROR
        | LeagueActionTypes.GET_LEAGUES
        | LeagueActionTypes.FIND_LEAGUE
        | LeagueActionTypes.CREATE_LEAGUE
        | LeagueActionTypes.UPDATE_LEAGUE
    payload: {} | string;
}

// common success
export const leaguesApiResponseSuccess = (actionType: string, data: League | League[] | {} | []): LeagueActionType => ({
    type: LeagueActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const leaguesApiResponseError = (actionType: string, error: string): LeagueActionType => ({
    type: LeagueActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getLeagues = (filter: FilterType): LeagueActionType => ({
    type: LeagueActionTypes.GET_LEAGUES,
    payload: filter,
});

export const findLeague = (leagueId: number): LeagueActionType => ({
    type: LeagueActionTypes.FIND_LEAGUE,
    payload: leagueId,
});

export const createLeague = (league: League): LeagueActionType => ({
    type: LeagueActionTypes.CREATE_LEAGUE,
    payload: league,
});

export const updateLeague = (league: League): LeagueActionType => ({
    type: LeagueActionTypes.UPDATE_LEAGUE,
    payload: league,
});
