import jwtDecode from 'jwt-decode';
import axios from 'axios';

import { mwError } from '../errors';

import config from '../../config';

// content type
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.baseURL = config.API_URL;

// intercepting to capture errors
axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        let message;

        switch (error.response.status) {
            case 401:
                message = 'Invalid credentials';
                break;
            case 403:
                message = 'Access Forbidden';
                break;
            case 404:
                message = 'Sorry! the data you are looking for could not be found';
                break;
            default: {
                message =
                    error?.response?.data?.['message'] ?? error?.message ?? error;
            }
        }
        return Promise.reject(mwError(error.response.status, message, error?.response?.data?.['extraData']));
    
    }
);

const AUTH_SESSION_KEY = 'fttcv_user';

/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token: string | null) => {
    if (token) axios.defaults.headers.common['Authorization'] = 'JWT ' + token;
    else delete axios.defaults.headers.common['Authorization'];
};

const getUserFromBrowserStorage = () => {
    const user = localStorage.getItem(AUTH_SESSION_KEY);
    return user ? (typeof user == 'object' ? user : JSON.parse(user)) : null;
};

export interface SortBy {key: string, order: string};

export interface FilterType {
    sortBy?: SortBy[], 
    where?: Array<{key: string, value: string}> | any, 
    pageSize?: number, 
    currentPage?: number,
}

export interface ColumnType {
    Header: string, 
    accessor: string, 
    sortName?: string, 
    sort?: boolean,
    classes?: string, 
    Cell?: Function, 
}

export interface PaginationResult<T> {
    count: number,
    rows: Array<T>,
    items_per_page?: number,
    current_page?: number,
    total_pages?: number,
}

interface ReqProps {
    url: string,
    data?: any
};

class APICore {
    /**
     * Fetches data from given url
     */
    get = ({url, data}: ReqProps) => {
        const params = data;
        let response;
        if (params) {
            var queryString = params
                ? Object.keys(params)
                      .map((key) => key + '=' + params[key])
                      .join('&')
                : '';
            response = axios.get(`${url}?${queryString}`, params);
        } else {
            response = axios.get(`${url}`, params);
        }
        return response;
    };

    getFile = ({url, data}: ReqProps) => {
        const params: any = data;
        let response;
        if (params) {
            var queryString = params
                ? Object.keys(params)
                      .map((key) => key + '=' + params[key])
                      .join('&')
                : '';
            response = axios.get(`${url}?${queryString}`, { responseType: 'blob' });
        } else {
            response = axios.get(`${url}`, { responseType: 'blob' });
        }
        return response;
    };

    getMultiple = ({url, data}: ReqProps) => {
        const params = data;
        const reqs = [];
        let queryString = '';
        if (params) {
            queryString = params
                ? Object.keys(params)
                      .map((key) => key + '=' + params[key])
                      .join('&')
                : '';
        }

        for (const u of url) {
            reqs.push(axios.get(`${u}?${queryString}`));
        }
        return axios.all(reqs);
    };

    /**
     * post given data to url
     */
    post = ({url, data}: ReqProps) => {
        return axios.post(url, data);
    };

    /**
     * Updates patch data
     */
    updatePatch = ({url, data}: ReqProps) => {
        return axios.patch(url, data);
    };

    /**
     * Updates data
     */
    update = ({url, data}: ReqProps) => {
        return axios.put(url, data);
    };

    /**
     * Deletes data
     */
    delete = ({url}: ReqProps) => {
        return axios.delete(url);
    };

    /**
     * post given data to url with file
     */
    postWithFile = ({url, data}: ReqProps) => {
        const formData = new FormData();
        for (const k in data) {
            formData.append(k, data[k]);
        }

        const config = {
            headers: {
                ...axios.defaults.headers,
                'content-type': 'multipart/form-data',
            },
        };
        return axios.post(url, formData, config);
    };

    /**
     * post given data to url with file
     */
    updateWithFile = ({url, data}: ReqProps) => {
        const formData = new FormData();
        for (const k in data) {
            formData.append(k, data[k]);
        }

        const config = {
            headers: {
                ...axios.defaults.headers,
                'content-type': 'multipart/form-data',
            },
        };
        return axios.patch(url, formData, config);
    };

    isUserAuthenticated = () => {
        const user = this.getLoggedInUser();

        if (!user) {
            return false;
        }
        const decoded: any = jwtDecode(user.accessToken);
        const currentTime = Date.now() / 1000;
        if (decoded.exp < currentTime) {
            console.warn('access token expired');
            return false;
        } else {
            // Double check the user data (jwtDecode does not validate the token, but if it is modified 
            // the server will detect it and return an error (i.e. the user won't be able to use the website))
            return decoded.id === user.id && decoded.role === user.role; 
        }
    };

    setLoggedInUser = (session: any) => {
        if (session) localStorage.setItem(AUTH_SESSION_KEY, JSON.stringify(session));
        else {
            localStorage.removeItem(AUTH_SESSION_KEY);
        }
    };
    /**
     * Returns the logged in user
     */
    getLoggedInUser = () => {
        return getUserFromBrowserStorage();
    };

    setUserInSession = (modifiedUser: any) => {
        let userInfo = localStorage.getItem(AUTH_SESSION_KEY);
        if (userInfo) {
            const { token, user } = JSON.parse(userInfo);
            this.setLoggedInUser({ token, ...user, ...modifiedUser });
        }
    };
}

/*
Check if token available in session
*/
let user = getUserFromBrowserStorage();
if (user) {
    const { accessToken } = user;
    if ( accessToken) {
        setAuthorization( accessToken);
    }
}

export { APICore, setAuthorization };
