// constants
import { OrderActionTypes } from './constants';
import Order, { OrderStatistics } from './model';

const INIT_STATE = {
    orders: [],
    shoppingCarts: [],
    statistics: undefined,
    order: undefined,
    loading: false,
    requestStatus: 0,
};

interface OrderActionType {
    type:
        | OrderActionTypes.API_RESPONSE_SUCCESS
        | OrderActionTypes.API_RESPONSE_ERROR
        | OrderActionTypes.GET_ORDERS
        | OrderActionTypes.FIND_ORDER
        | OrderActionTypes.UPDATE_ORDER
        | OrderActionTypes.GET_SHOPPING_CARTS
        | OrderActionTypes.GET_STATISTICS
        | OrderActionTypes.RESET
    payload: {
        actionType?: string;
        data?: Order | {};
        error?: string;
    };
}

interface State {
    orders?: Order[] | {};
    shoppingCarts?: Order[] | {};
    order?: Order;
    statistics?: OrderStatistics;
    loading?: boolean;
    requestStatus?: number;
}

const OrderReducer = (state: State = INIT_STATE, action: OrderActionType): any => {
    switch (action.type) {
        case OrderActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case OrderActionTypes.GET_ORDERS: {
                    return {
                        ...state,
                        orders: action.payload.data,
                        loading: false,
                    };
                }
                case OrderActionTypes.FIND_ORDER: {
                    return {
                        ...state,
                        order: action.payload.data,
                        loading: false,
                        requestStatus: 1,
                    };
                }
                case OrderActionTypes.UPDATE_ORDER: {
                    return {
                        ...state,
                        loading: false,
                        requestStatus: 1,
                    };
                }
                case OrderActionTypes.GET_SHOPPING_CARTS: {
                    return {
                        ...state,
                        shoppingCarts: action.payload.data,
                        loading: false,
                    };
                }
                case OrderActionTypes.GET_STATISTICS: {
                    return {
                        ...state,
                        statistics: action.payload.data,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case OrderActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                default:
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                        requestStatus: -1,
                    };
            }
        case OrderActionTypes.GET_ORDERS:
            return { ...state, loading: true, requestStatus: 0};
        case OrderActionTypes.FIND_ORDER:
            return { ...state, loading: true, requestStatus: 0, order: undefined};
        case OrderActionTypes.UPDATE_ORDER:
            return { ...state, loading: true, requestStatus: 0};
        case OrderActionTypes.GET_SHOPPING_CARTS:
            return { ...state, loading: true, requestStatus: 0};
        case OrderActionTypes.GET_STATISTICS:
            return { ...state, loading: true, requestStatus: 0, statistics: undefined};
        case OrderActionTypes.RESET:
            return INIT_STATE;
        default:
            return { ...state };
    }
};

export default OrderReducer;
