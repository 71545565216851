// constants
import { CategoryActionTypes } from './constants';
import Category from './model';

const INIT_STATE = {
    categories: [],
    loading: false,
    requestStatus: 0,
};

interface CategoryActionType {
    type:
        | CategoryActionTypes.API_RESPONSE_SUCCESS
        | CategoryActionTypes.API_RESPONSE_ERROR
        | CategoryActionTypes.GET_CATEGORIES
    payload: {
        actionType?: string;
        data?: Category | {};
        error?: string;
    };
}

interface State {
    categories?: Category[] | {};
    loading?: boolean;
    requestStatus?: number;
}

const CategoryReducer = (state: State = INIT_STATE, action: CategoryActionType): any => {
    switch (action.type) {
        case CategoryActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case CategoryActionTypes.GET_CATEGORIES: {
                    return {
                        ...state,
                        categories: action.payload.data,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case CategoryActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                default:
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                        requestStatus: -1,
                    };
            }
        case CategoryActionTypes.GET_CATEGORIES:
            return { ...state, loading: true, requestStatus: 0};
        default:
            return { ...state };
    }
};

export default CategoryReducer;
