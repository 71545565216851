import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { mwErrorHandling } from '../../helpers/errors';

// apicore
import { APICore } from '../../helpers/api/apiCore';

// actions
import { ageGroupsApiResponseSuccess, ageGroupsApiResponseError } from './actions';

// constants
import { AgeGroupActionTypes } from './constants';

const api = new APICore();
const BASE_URL = '/age-groups/';

/**
 * Get all ageGroups
 */
function* getAll(): SagaIterator {
    try {
        const response = yield call(api.get, {url: BASE_URL});
        yield put(ageGroupsApiResponseSuccess(AgeGroupActionTypes.GET_AGE_GROUPS, response.data));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(ageGroupsApiResponseError(AgeGroupActionTypes.GET_AGE_GROUPS, error));
    }
}

export function* watchGetAllAgeGroups() {
    yield takeEvery(AgeGroupActionTypes.GET_AGE_GROUPS, getAll);
}


function* ageGroupsSaga() {
    yield all([fork(watchGetAllAgeGroups)]);
}

export default ageGroupsSaga;
