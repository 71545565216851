// constants
import { FilterType } from '../../helpers/api/apiCore';
import { TournamentActionTypes } from './constants';
import Tournament from './model';

export interface TournamentActionType {
    type:
        | TournamentActionTypes.API_RESPONSE_SUCCESS
        | TournamentActionTypes.API_RESPONSE_ERROR
        | TournamentActionTypes.GET_TOURNAMENTS
        | TournamentActionTypes.FIND_TOURNAMENT
        | TournamentActionTypes.CREATE_TOURNAMENT
        | TournamentActionTypes.UPDATE_TOURNAMENT
        | TournamentActionTypes.DELETE_TOURNAMENT
    payload: {} | string;
}

// common success
export const tournamentsApiResponseSuccess = (actionType: string, data: Tournament | Tournament[] | {} | []): TournamentActionType => ({
    type: TournamentActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const tournamentsApiResponseError = (actionType: string, error: string): TournamentActionType => ({
    type: TournamentActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getTournaments = (filter: FilterType): TournamentActionType => ({
    type: TournamentActionTypes.GET_TOURNAMENTS,
    payload: filter,
});

export const findTournament = (tournamentId: number): TournamentActionType => ({
    type: TournamentActionTypes.FIND_TOURNAMENT,
    payload: tournamentId,
});

export const createTournament = (tournament: Tournament): TournamentActionType => ({
    type: TournamentActionTypes.CREATE_TOURNAMENT,
    payload: tournament,
});

export const updateTournament = (tournament: Tournament): TournamentActionType => ({
    type: TournamentActionTypes.UPDATE_TOURNAMENT,
    payload: tournament,
});

export const deleteTournament = (tournament: Tournament): TournamentActionType => ({
    type: TournamentActionTypes.DELETE_TOURNAMENT,
    payload: tournament,
});
