// constants
import { OrderLineActionTypes } from './constants';
import OrderLine from './model';

export interface OrderLineActionType {
    type:
        | OrderLineActionTypes.API_RESPONSE_SUCCESS
        | OrderLineActionTypes.API_RESPONSE_ERROR
        | OrderLineActionTypes.FIND_ORDERLINE
        | OrderLineActionTypes.CREATE_ORDERLINE
        | OrderLineActionTypes.UPDATE_ORDERLINE
        | OrderLineActionTypes.DELETE_ORDERLINE
    payload: {} | string;
}

// common success
export const orderLinesApiResponseSuccess = (actionType: string, data: OrderLine | OrderLine[] | {} | []): OrderLineActionType => ({
    type: OrderLineActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const orderLinesApiResponseError = (actionType: string, error: string): OrderLineActionType => ({
    type: OrderLineActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const findOrderLine = (orderLineId: number): OrderLineActionType => ({
    type: OrderLineActionTypes.FIND_ORDERLINE,
    payload: orderLineId,
});

export const createOrderLine = (orderLine: OrderLine): OrderLineActionType => ({
    type: OrderLineActionTypes.CREATE_ORDERLINE,
    payload: orderLine,
});

export const updateOrderLine = (orderLine: OrderLine): OrderLineActionType => ({
    type: OrderLineActionTypes.UPDATE_ORDERLINE,
    payload: orderLine,
});

export const deleteOrderLine = (orderLine: OrderLine): OrderLineActionType => ({
    type: OrderLineActionTypes.DELETE_ORDERLINE,
    payload: orderLine,
});
