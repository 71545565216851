export enum PersonActionTypes {
    API_RESPONSE_SUCCESS = '@@persons/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@persons/API_RESPONSE_ERROR',

    GET_PERSONS = '@@persons/GET_PERSONS',
    FIND_PERSON = '@@persons/FIND_PERSON',
    UPDATE_PERSON = '@@persons/UPDATE_PERSON',
    DELETE_PERSON = '@@persons/DELETE_PERSON',
    CREATE_PERSON = '@@persons/CREATE_PERSON',
}
