// constants
import { LicenseRoleActionTypes } from './constants';
import LicenseRole from './model';

const INIT_STATE = {
    licenseRoles: [],
    loading: false,
    requestStatus: 0,
};

interface LicenseRoleActionType {
    type:
        | LicenseRoleActionTypes.API_RESPONSE_SUCCESS
        | LicenseRoleActionTypes.API_RESPONSE_ERROR
        | LicenseRoleActionTypes.GET_LICENSE_ROLES
    payload: {
        actionType?: string;
        data?: LicenseRole | {};
        error?: string;
    };
}

interface State {
    licenseRoles?: LicenseRole[] | {};
    loading?: boolean;
    requestStatus?: number;
}

const LicenseRoleReducer = (state: State = INIT_STATE, action: LicenseRoleActionType): any => {
    switch (action.type) {
        case LicenseRoleActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case LicenseRoleActionTypes.GET_LICENSE_ROLES: {
                    return {
                        ...state,
                        licenseRoles: action.payload.data,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case LicenseRoleActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                default:
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                        requestStatus: -1,
                    };
            }
        case LicenseRoleActionTypes.GET_LICENSE_ROLES:
            return { ...state, loading: true, requestStatus: 0};
        default:
            return { ...state };
    }
};

export default LicenseRoleReducer;
