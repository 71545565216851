export enum MatchActionTypes {
    API_RESPONSE_SUCCESS = '@@matches/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@matches/API_RESPONSE_ERROR',

    GET_MATCHES = '@@matches/GET_MATCHES',
    FIND_MATCH = '@@matches/FIND_MATCH',
    UPDATE_MATCH = '@@matches/UPDATE_MATCH',
    DELETE_MATCH = '@@matches/DELETE_MATCH',
    CREATE_MATCH = '@@matches/CREATE_MATCH',
}
