import { APICore } from './apiCore';

const api = new APICore();

// account
function login(params: { username: string; password: string }) {
    const baseUrl = '/auth/signin';
    return api.post({url:`${baseUrl}`, data: params});
}

function logout() {
    const baseUrl = '/logout/';
    return api.post({url:`${baseUrl}`, data:{}});
}

function signup(params: { fullname: string; email: string; password: string }) {
    const baseUrl = '/register/';
    return api.post({url: `${baseUrl}`, data:params});
}

function forgotPassword(params: { username: string }) {
    const baseUrl = '/forget-password/';
    return api.post({url:`${baseUrl}`, data: params});
}

export { login, logout, signup, forgotPassword };
