// constants
import { PaginationResult } from '../../helpers/api/apiCore';
import { MatchActionTypes } from './constants';
import Match from './model';

const INIT_STATE = {
    matches: undefined,
    loading: false,
    requestStatus: 0,
};

interface MatchActionType {
    type:
        | MatchActionTypes.API_RESPONSE_SUCCESS
        | MatchActionTypes.API_RESPONSE_ERROR
        | MatchActionTypes.GET_MATCHES
        | MatchActionTypes.FIND_MATCH
        | MatchActionTypes.CREATE_MATCH
        | MatchActionTypes.UPDATE_MATCH
    payload: {
        actionType?: string;
        data?: Match | {} | PaginationResult<Match>;
        error?: string;
    };
}

interface State {
    matches?: PaginationResult<Match>;
    match?: Match;
    loading?: boolean;
    requestStatus?: number;
}

const MatchReducer = (state: State = INIT_STATE, action: MatchActionType): any => {
    switch (action.type) {
        case MatchActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case MatchActionTypes.GET_MATCHES: {
                    return {
                        ...state,
                        matches: action.payload.data,
                        loading: false,
                    };
                }
                case MatchActionTypes.FIND_MATCH: {
                    return {
                        ...state,
                        match: action.payload.data,
                        loading: false,
                    };
                }
                case MatchActionTypes.CREATE_MATCH: {
                    return {
                        ...state,
                        loading: false,
                        requestStatus: 1,
                    };
                }
                case MatchActionTypes.UPDATE_MATCH: {
                    return {
                        ...state,
                        loading: false,
                        requestStatus: 1,
                    };
                }
                default:
                    return { ...state };
            }

        case MatchActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                default:
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                        requestStatus: -1,
                    };
            }
        case MatchActionTypes.GET_MATCHES:
            return { ...state, loading: true, requestStatus: 0};
        case MatchActionTypes.FIND_MATCH:
            return { ...state, loading: true, requestStatus: 0};
        case MatchActionTypes.CREATE_MATCH:
            return { ...state, loading: true, requestStatus: 0};
        case MatchActionTypes.UPDATE_MATCH:
            return { ...state, loading: true, requestStatus: 0};
        default:
            return { ...state };
    }
};

export default MatchReducer;
