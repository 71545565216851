// constants
import { ConstantActionTypes } from './constants';
import Constant from './model';

const INIT_STATE = {
    constants: [],
    loading: false,
    requestStatus: 0,
};

interface ConstantActionType {
    type:
        | ConstantActionTypes.API_RESPONSE_SUCCESS
        | ConstantActionTypes.API_RESPONSE_ERROR
        | ConstantActionTypes.GET_CONSTANTS
        | ConstantActionTypes.UPDATE_CONSTANT
    payload: {
        actionType?: string;
        data?: Constant | {};
        error?: string;
    };
}

interface State {
    constants?: Constant[] | {};
    loading?: boolean;
    requestStatus?: number;
}

const ConstantReducer = (state: State = INIT_STATE, action: ConstantActionType): any => {
    switch (action.type) {
        case ConstantActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case ConstantActionTypes.GET_CONSTANTS: {
                    return {
                        ...state,
                        constants: action.payload.data,
                        loading: false,
                    };
                }
                case ConstantActionTypes.UPDATE_CONSTANT: {
                    return {
                        ...state,
                        loading: false,
                        requestStatus: 1,
                    };
                }
                default:
                    return { ...state };
            }

        case ConstantActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                default:
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                        requestStatus: -1,
                    };
            }
        case ConstantActionTypes.GET_CONSTANTS:
            return { ...state, loading: true, requestStatus: 0};
        case ConstantActionTypes.UPDATE_CONSTANT:
            return { ...state, loading: true, requestStatus: 0};
        default:
            return { ...state };
    }
};

export default ConstantReducer;
