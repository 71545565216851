import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { mwErrorHandling } from '../../helpers/errors';

// apicore
import { APICore } from '../../helpers/api/apiCore';

// actions
import { paymentsApiResponseSuccess, paymentsApiResponseError } from './actions';

// constants
import { PaymentActionTypes } from './constants';
import Payment from './model';

interface PaymentData {
    payload: Payment;
    type: string;
}

const api = new APICore();
const BASE_URL = '/payments/';


function* requestPayment({payload}: PaymentData): SagaIterator {
    try {
        const response = yield call(api.post, {url: BASE_URL + 'request', data: payload});
        const created = response.data;
        yield put(paymentsApiResponseSuccess(PaymentActionTypes.REQUEST_PAYMENT, created));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(paymentsApiResponseError(PaymentActionTypes.REQUEST_PAYMENT, error));
    }
}

function* getRedirectParameters({payload}: {payload: any, type: string}): SagaIterator {
    try {
        const response = yield call(api.post, {url: BASE_URL + 'redirectParameteres', data: payload});
        const created = response.data;
        yield put(paymentsApiResponseSuccess(PaymentActionTypes.GET_REDIRECT_PARAMS, created));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(paymentsApiResponseError(PaymentActionTypes.GET_REDIRECT_PARAMS, error));
    }
}

function* getInSiteParameters({payload}: {payload: any, type: string}): SagaIterator {
    try {
        const response = yield call(api.post, {url: BASE_URL + 'insiteParameteres', data: payload});
        const created = response.data;
        yield put(paymentsApiResponseSuccess(PaymentActionTypes.GET_INSITE_PARAMS, created));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(paymentsApiResponseError(PaymentActionTypes.GET_INSITE_PARAMS, error));
    }
}


export function* watchCreatePayment() {
    yield takeEvery(PaymentActionTypes.REQUEST_PAYMENT, requestPayment);
}

export function* watchGetRedirectParams() {
    yield takeEvery(PaymentActionTypes.GET_REDIRECT_PARAMS, getRedirectParameters);
}

export function* watchGetInsiteParams() {
    yield takeEvery(PaymentActionTypes.GET_INSITE_PARAMS, getInSiteParameters);
}

function* paymentsSaga() {
    yield all([fork(watchCreatePayment), fork(watchGetRedirectParams), fork(watchGetInsiteParams)]);
}

export default paymentsSaga;
