// constants
import { ClubActionTypes } from './constants';
import Club from './model';

const INIT_STATE = {
    clubs: [],
    loading: false,
    requestStatus: 0,
};

interface ClubActionType {
    type:
        | ClubActionTypes.API_RESPONSE_SUCCESS
        | ClubActionTypes.API_RESPONSE_ERROR
        | ClubActionTypes.GET_CLUBS
        | ClubActionTypes.CREATE_CLUB
        | ClubActionTypes.UPDATE_CLUB
        | ClubActionTypes.RENEW_CLUB
    payload: {
        actionType?: string;
        data?: Club | {};
        error?: string;
    };
}

interface State {
    clubs?: Club[] | {};
    loading?: boolean;
    value?: boolean;
    requestStatus?: number;
}

const ClubReducer = (state: State = INIT_STATE, action: ClubActionType): any => {
    switch (action.type) {
        case ClubActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case ClubActionTypes.GET_CLUBS: {
                    return {
                        ...state,
                        clubs: action.payload.data,
                        loading: false,
                    };
                }
                case ClubActionTypes.CREATE_CLUB: {
                    return {
                        ...state,
                        loading: false,
                        requestStatus: 1,
                    };
                }
                case ClubActionTypes.UPDATE_CLUB: {
                    return {
                        ...state,
                        loading: false,
                        requestStatus: 1,
                    };
                }
                case ClubActionTypes.RENEW_CLUB: {
                    return {
                        ...state,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case ClubActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                default:
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                        requestStatus: -1,
                    };
            }
        case ClubActionTypes.GET_CLUBS:
            return { ...state, loading: true, requestStatus: 0};
        case ClubActionTypes.CREATE_CLUB:
            return { ...state, loading: true, requestStatus: 0};
        case ClubActionTypes.UPDATE_CLUB:
            return { ...state, loading: true, requestStatus: 0};
        case ClubActionTypes.RENEW_CLUB:
            return { ...state, loading: true, requestStatus: 0};
        default:
            return { ...state };
    }
};

export default ClubReducer;
