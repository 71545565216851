// constants
import { CategoryActionTypes } from './constants';
import Category from './model';

export interface CategoryActionType {
    type:
        | CategoryActionTypes.API_RESPONSE_SUCCESS
        | CategoryActionTypes.API_RESPONSE_ERROR
        | CategoryActionTypes.GET_CATEGORIES
    payload: {} | string;
}

// common success
export const categoriesApiResponseSuccess = (actionType: string, data: Category | Category[] | {} | []): CategoryActionType => ({
    type: CategoryActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const categoriesApiResponseError = (actionType: string, error: string): CategoryActionType => ({
    type: CategoryActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getCategories = (): CategoryActionType => ({
    type: CategoryActionTypes.GET_CATEGORIES,
    payload: {},
});

