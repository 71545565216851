import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { mwErrorHandling } from '../../helpers/errors';

// apicore
import { APICore, FilterType } from '../../helpers/api/apiCore';

// actions
import { passesApiResponseSuccess, passesApiResponseError } from './actions';

// constants
import { PassActionTypes } from './constants';
import Pass from './model';

interface PassData {
    payload: Pass;
    type: string;
}

interface FilterData {
    payload: FilterType;
    type: string;
}

const api = new APICore();
const BASE_URL = '/passes/';

/**
 * Get all passes
 */
function* getAll({payload}: FilterData): SagaIterator {
    try {
        const { sortBy, where, pageSize, currentPage } = payload; // eslint-disable-line
        const response = yield call(api.get, {url: `${BASE_URL}?where=${encodeURIComponent(where ? JSON.stringify(where) : "{}")}&limit=${encodeURIComponent(pageSize ?? 20)}&page=${encodeURIComponent(currentPage ?? 1)}`});
        yield put(passesApiResponseSuccess(PassActionTypes.GET_PASSES, response.data));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(passesApiResponseError(PassActionTypes.GET_PASSES, error));
    }
}

function* createPass({payload}: PassData): SagaIterator {
    try {
		const { name, availableUntil, sex, type, ageGroupsIds, licenseTypesIds, product, isSpecial } = payload;
		const createFields = { name, availableUntil, sex, type, ageGroupsIds, licenseTypesIds, product, isSpecial };
        const response = yield call(api.post, {url: `${BASE_URL}`, data: createFields});
        const created = response.data;
        yield put(passesApiResponseSuccess(PassActionTypes.CREATE_PASS, created));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(passesApiResponseError(PassActionTypes.CREATE_PASS, error));
    }
}

function* updatePass({payload}: PassData): SagaIterator {
    try {
		const { name, availableUntil, sex, type, ageGroupsIds, licenseTypesIds, product } = payload;
		const updateFields = { name, availableUntil, sex, type, ageGroupsIds, licenseTypesIds, product };
        const response = yield call(api.update, {url: `${BASE_URL}${payload.id ?? 0}`, data: updateFields});
        const updated = response.data;
        yield put(passesApiResponseSuccess(PassActionTypes.UPDATE_PASS, updated));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(passesApiResponseError(PassActionTypes.UPDATE_PASS, error));
    }
}

function* deletePass({payload}: PassData): SagaIterator {
    try {
        const response = yield call(api.delete, {url: `${BASE_URL}${payload ?? 0}`});
        const deleted = response.data;
        yield put(passesApiResponseSuccess(PassActionTypes.DELETE_PASS, deleted));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(passesApiResponseError(PassActionTypes.DELETE_PASS, error));
    }
}

export function* watchGetAllPasses() {
    yield takeEvery(PassActionTypes.GET_PASSES, getAll);
}

export function* watchCreatePass() {
    yield takeEvery(PassActionTypes.CREATE_PASS, createPass);
}

export function* watchUpdatePass() {
    yield takeEvery(PassActionTypes.UPDATE_PASS, updatePass);
}

export function* watchDeletePass() {
    yield takeEvery(PassActionTypes.DELETE_PASS, deletePass);
}

function* passesSaga() {
    yield all([fork(watchGetAllPasses), fork(watchCreatePass), fork(watchUpdatePass), fork(watchDeletePass)]);
}

export default passesSaga;
