import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

// apicore
import { APICore, setAuthorization } from '../../helpers/api/apiCore';

// helpers
import {
    login as loginApi,
    //logout as logoutApi,
    signup as signupApi,
    forgotPassword as forgotPasswordApi,
} from '../../helpers/';
import { mwErrorHandling } from '../../helpers/errors';

// actions
import { authApiResponseSuccess, authApiResponseError } from './actions';
import { resetOrdersData } from '../actions';

// constants
import { AuthActionTypes } from './constants';

// models
import User from '../user/model';

interface UserData {
    payload: {
        username: string;
        password: string;
        fullname: string;
        email: string;
    };
    type: string;
}

const api = new APICore();

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* login({ payload: { username, password }, type }: UserData): SagaIterator {
    try {
        const response = yield call(loginApi, { username, password });
        const user: User = {
            id: response.data.id,
            username: response.data.username,
            active: response.data.active,
            role: response.data.role,
            person: response.data.person,
            club: response.data.club,
            accessToken: response.data.accessToken
        };
        api.setLoggedInUser(user);
        setAuthorization(user.accessToken ?? null);
        yield put(authApiResponseSuccess(AuthActionTypes.LOGIN_USER, user));
    } catch (error: any) {
        yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, error));
        api.setLoggedInUser(null);
        setAuthorization(null);
    }
}

/**
 * Logout the user
 */
function* logout(): SagaIterator {
    try {
        //yield call(logoutApi);
        api.setLoggedInUser(null);
        setAuthorization(null);
        yield put(resetOrdersData());
        yield put(authApiResponseSuccess(AuthActionTypes.LOGOUT_USER, {}));
    } catch (error: any) {
        yield put(authApiResponseError(AuthActionTypes.LOGOUT_USER, error));
    }
}

function* signup({ payload: { fullname, email, password } }: UserData): SagaIterator {
    try {
        const response = yield call(signupApi, { fullname, email, password });
        const user = response.data;
        // api.setLoggedInUser(user);
        // setAuthorization(user['token']);
        yield put(authApiResponseSuccess(AuthActionTypes.SIGNUP_USER, user));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) {
            yield put(authApiResponseError(AuthActionTypes.SIGNUP_USER, error));
            api.setLoggedInUser(null);
            setAuthorization(null);
        }
    }
}

function* forgotPassword({ payload: { username } }: UserData): SagaIterator {
    try {
        const response = yield call(forgotPasswordApi, { username });
        yield put(authApiResponseSuccess(AuthActionTypes.FORGOT_PASSWORD, response.data));
    } catch (error: any) {
        yield put(authApiResponseError(AuthActionTypes.FORGOT_PASSWORD, error));
    }
}

/**
 * Refresh logged in user data
 * @param {*} payload - username and password
 */
 function* refreshUser(): SagaIterator {     
    try {
        const response = yield  call(api.post, {url: `/auth/profile`, data: {}});
        const oldToken = api.getLoggedInUser().accessToken;
        const user: User = {
            id: response.data.id,
            username: response.data.username,
            active: response.data.active,
            role: response.data.role,
            person: response.data.person,
            club: response.data.club,
            accessToken: oldToken,
        };
        api.setLoggedInUser(user);
        yield put(authApiResponseSuccess(AuthActionTypes.REFRESH_USER, user));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(authApiResponseError(AuthActionTypes.REFRESH_USER, error));
    }
}


export function* watchLoginUser() {
    yield takeEvery(AuthActionTypes.LOGIN_USER, login);
}

export function* watchLogout() {
    yield takeEvery(AuthActionTypes.LOGOUT_USER, logout);
}

export function* watchSignup(): any {
    yield takeEvery(AuthActionTypes.SIGNUP_USER, signup);
}

export function* watchForgotPassword(): any {
    yield takeEvery(AuthActionTypes.FORGOT_PASSWORD, forgotPassword);
}

export function* watchRefreshUser(): any {
    yield takeEvery(AuthActionTypes.REFRESH_USER, refreshUser);
}

function* authSaga() {
    yield all([fork(watchLoginUser), fork(watchLogout), fork(watchSignup), fork(watchForgotPassword), fork(watchRefreshUser)]);
}

export default authSaga;
