import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { mwErrorHandling } from '../../helpers/errors';

// apicore
import { APICore } from '../../helpers/api/apiCore';

// actions
import { productsApiResponseSuccess, productsApiResponseError } from './actions';

// constants
import { ProductActionTypes } from './constants';
import Product from './model';

interface ProductData {
    payload: Product;
    type: string;
}

const api = new APICore();
const BASE_URL = '/products/';

/**
 * Logout the user
 */
function* getAll(): SagaIterator {
    try {
        const response = yield call(api.get, {url: BASE_URL});
        yield put(productsApiResponseSuccess(ProductActionTypes.GET_PRODUCTS, response.data));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(productsApiResponseError(ProductActionTypes.GET_PRODUCTS, error));
    }
}

function* updateProduct({payload}: ProductData): SagaIterator {
    try {
		const { name, description, price } = payload;
		const updateFields = { name, description, price };
        const response = yield call(api.update, {url: `${BASE_URL}${payload.id ?? 0}`, data: updateFields});
        const updated = response.data;
        yield put(productsApiResponseSuccess(ProductActionTypes.UPDATE_PRODUCT, updated));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(productsApiResponseError(ProductActionTypes.UPDATE_PRODUCT, error));
    }
}

export function* watchGetAllProducts() {
    yield takeEvery(ProductActionTypes.GET_PRODUCTS, getAll);
}

export function* watchUpdateProduct() {
    yield takeEvery(ProductActionTypes.UPDATE_PRODUCT, updateProduct);
}

function* productsSaga() {
    yield all([fork(watchGetAllProducts), fork(watchUpdateProduct)]);
}

export default productsSaga;
