export enum EncounterActionTypes {
    API_RESPONSE_SUCCESS = '@@encounters/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@encounters/API_RESPONSE_ERROR',

    GET_ENCOUNTERS = '@@encounters/GET_ENCOUNTERS',
    GET_DAY_ENCOUNTERS = '@@encounters/GET_DAY_ENCOUNTERS',
    FIND_ENCOUNTER = '@@encounters/FIND_ENCOUNTER',
    UPDATE_ENCOUNTER = '@@encounters/UPDATE_ENCOUNTER',
    DELETE_ENCOUNTER = '@@encounters/DELETE_ENCOUNTER',
    CREATE_ENCOUNTER = '@@encounters/CREATE_ENCOUNTER',
    IMPORT_ENCOUNTERS = '@@encounters/IMPORT_ENCOUNTERS',

    ADD_ENCOUNTER_RESULTS = '@@encounters/ADD_ENCOUNTER_RESULTS',
}
