import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { mwErrorHandling } from '../../helpers/errors';

// apicore
import { APICore, FilterType, SortBy } from '../../helpers/api/apiCore';
import { sortByToQueryParams } from '../../helpers/utils';

// actions
import { registrationsApiResponseSuccess, registrationsApiResponseError } from './actions';

// constants
import { RegistrationActionTypes } from './constants';
import Registration from './model';
import License from '../license/model';
import Tournament from '../tournament/model';

interface RegistrationData {
    payload: Registration;
    type: string;
}

interface FilterData {
    payload: FilterType;
    type: string;
}

const api = new APICore();
const BASE_URL = '/registrations/';

/**
 * Fetch registrations in pagination container
 */
function* getAll({payload}: FilterData): SagaIterator {
    try {        
        const { sortBy, where, pageSize, currentPage } = payload;
        const defaultOrder: SortBy[] = [{ key: 'id', order: 'ASC' }];
        const response = yield call(api.get, {url: `${BASE_URL}?where=${encodeURIComponent(where ? JSON.stringify(where) : "{}")}&order=${sortByToQueryParams(sortBy, defaultOrder)}&limit=${encodeURIComponent(pageSize ?? 20)}&page=${encodeURIComponent(currentPage ?? 1)}`});        
        yield put(registrationsApiResponseSuccess(RegistrationActionTypes.GET_REGISTRATIONS, response.data));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(registrationsApiResponseError(RegistrationActionTypes.GET_REGISTRATIONS, error));
    }
}

/**
 * Fetch available registrations in pagination container
 */
 function* getAvailableRegistrations({payload}: {type: string, payload: {tournamentId: number, passesIds: number[]}}): SagaIterator {
    try {        
        const { tournamentId, passesIds } = payload;
        const defaultOrder: SortBy[] = [{ key: 'id', order: 'ASC' }];
        const response = yield call(api.get, {url: `${BASE_URL}?where=${encodeURIComponent(JSON.stringify({onlyAvailable: {tournamentId, passesIds}}))}&order=${sortByToQueryParams(undefined, defaultOrder)}&limit=${encodeURIComponent(999999)}&page=${encodeURIComponent(1)}`});        
        yield put(registrationsApiResponseSuccess(RegistrationActionTypes.GET_AVAILABLE_REGISTRATIONS, response.data));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(registrationsApiResponseError(RegistrationActionTypes.GET_AVAILABLE_REGISTRATIONS, error));
    }
}


/**
 * Find given registration
 */
 function* findOne({payload}: {payload: number, type: string}): SagaIterator {
    try {
        const response = yield call(api.get, {url: `${BASE_URL}${payload}`});
        yield put(registrationsApiResponseSuccess(RegistrationActionTypes.FIND_REGISTRATION, response.data));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(registrationsApiResponseError(RegistrationActionTypes.FIND_REGISTRATION, error));
    }
}

function* createRegistration({payload}: RegistrationData): SagaIterator {
    try {
		const { licenses, tournaments, pass } = payload;
        const response = yield call(api.post, {url: `${BASE_URL}`, data: {licenses: licenses?.filter((l: License) => typeof l !== 'undefined').map((l: License) => l.id ?? 0) ?? [], tournaments: tournaments?.map((t: Tournament) => t.id ?? 0) ?? [], passId: pass?.id ?? 0}});
        const created = response.data;
        yield put(registrationsApiResponseSuccess(RegistrationActionTypes.CREATE_REGISTRATION, created));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(registrationsApiResponseError(RegistrationActionTypes.CREATE_REGISTRATION, error));
    }
}

function* updateRegistration({payload}: RegistrationData): SagaIterator {
    try {
		const { tournaments } = payload;
        const response = yield call(api.update, {url: `${BASE_URL}${payload.id ?? 0}`, data: {tournaments: tournaments ?? []}});
        const updated = response.data;
        yield put(registrationsApiResponseSuccess(RegistrationActionTypes.UPDATE_REGISTRATION, updated));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(registrationsApiResponseError(RegistrationActionTypes.UPDATE_REGISTRATION, error));
    }
}

function* deleteRegistration({payload}: RegistrationData): SagaIterator {
    try {
        const response = yield call(api.delete, {url: `${BASE_URL}${payload.id ?? 0}`});
        const deleted = response.data;
        yield put(registrationsApiResponseSuccess(RegistrationActionTypes.DELETE_REGISTRATION, deleted));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(registrationsApiResponseError(RegistrationActionTypes.DELETE_REGISTRATION, error));
    }
}


export function* watchGetAllRegistrations() {
    yield takeEvery(RegistrationActionTypes.GET_REGISTRATIONS, getAll);
}

export function* watchGetAvailableRegistrations() {
    yield takeEvery(RegistrationActionTypes.GET_AVAILABLE_REGISTRATIONS, getAvailableRegistrations);
}

export function* watchFindRegistration() {
    yield takeEvery(RegistrationActionTypes.FIND_REGISTRATION, findOne);
}

export function* watchUpdateRegistration() {
    yield takeEvery(RegistrationActionTypes.UPDATE_REGISTRATION, updateRegistration);
}

export function* watchCreateRegistration() {
    yield takeEvery(RegistrationActionTypes.CREATE_REGISTRATION, createRegistration);
}

export function* watchDeleteOrderLine() {
    yield takeEvery(RegistrationActionTypes.DELETE_REGISTRATION, deleteRegistration);
}

function* registrationsSaga() {
    yield all([fork(watchGetAllRegistrations), fork(watchFindRegistration), fork(watchUpdateRegistration), fork(watchCreateRegistration), fork(watchGetAvailableRegistrations), fork(watchDeleteOrderLine)]);
}

export default registrationsSaga;
