import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { mwErrorHandling } from '../../helpers/errors';

// apicore
import { APICore } from '../../helpers/api/apiCore';

// actions
import { usersApiResponseSuccess, usersApiResponseError } from './actions';

// constants and models
import { UserActionTypes } from './constants';
import User from './model';

interface UserData {
    payload: User;
    type: string;
}

const api = new APICore();
const BASE_URL = '/users/';

/**
 * Logout the user
 */
function* getAll(): SagaIterator {
    try {
        const response = yield call(api.get, {url: BASE_URL});
        yield put(usersApiResponseSuccess(UserActionTypes.GET_USERS, response.data));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(usersApiResponseError(UserActionTypes.GET_USERS, error));
    }
}

function* createUser({payload}: UserData): SagaIterator {
    try {
        const { username, password, active, role, person, club } = payload;
        const newUserData = {
            username: username,
            password: password,
            active: active,
            role: role,
            person_id: person?.id,
            club_id: club?.id,
        }
        const response = yield call(api.post, {url: '/auth/signup', data: newUserData});
        const created = response.data;
        yield put(usersApiResponseSuccess(UserActionTypes.CREATE_USER, created));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(usersApiResponseError(UserActionTypes.CREATE_USER, error));
    }
}

function* updateUser({payload}: UserData): SagaIterator {
    try {
        const { username, password, active, role, person, club } = payload;
        const newUserData = {
            username: username,
            password: password,
            active: active,
            role: role,
            person_id: person?.id,
            club_id: club?.id,
        }
        const response = yield call(api.update, {url: `${BASE_URL}${payload.id ?? 0}`, data: newUserData});
        const updated = response.data;
        yield put(usersApiResponseSuccess(UserActionTypes.UPDATE_USER, updated));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(usersApiResponseError(UserActionTypes.UPDATE_USER, error));
    }
}

function* deleteUser({payload}: UserData): SagaIterator {
    try {
        const response = yield call(api.delete, {url: `${BASE_URL}${payload.id ?? 0}`});
        yield put(usersApiResponseSuccess(UserActionTypes.DELETE_USER, response));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(usersApiResponseError(UserActionTypes.DELETE_USER, error));
    }
}

export function* watchGetAllUsers() {
    yield takeEvery(UserActionTypes.GET_USERS, getAll);
}

export function* watchCreateUser() {
    yield takeEvery(UserActionTypes.CREATE_USER, createUser);
}

export function* watchUpdateUser() {
    yield takeEvery(UserActionTypes.UPDATE_USER, updateUser);
}

export function* watchDeleteUser() {
    yield takeEvery(UserActionTypes.DELETE_USER, deleteUser);
}

function* usersSaga() {
    yield all([fork(watchGetAllUsers), fork(watchCreateUser), fork(watchUpdateUser), fork(watchDeleteUser)]);
}

export default usersSaga;
