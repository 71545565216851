import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { mwErrorHandling } from '../../helpers/errors';

// apicore
import { APICore, FilterType } from '../../helpers/api/apiCore';
import { sortByToQueryParams } from '../../helpers/utils';

// actions
import { licensesApiResponseSuccess, licensesApiResponseError } from './actions';
import { getShoppingCarts } from '../actions';

// constants
import { LicenseActionTypes } from './constants';
import License from './model';

interface LicenseData {
    payload: License;
    type: string;
}

interface FilterData {
    payload: FilterType;
    type: string;
}

const api = new APICore();
const BASE_URL = '/licenses/';

/**
 * Fetch licenses in pagination container
 */
function* getAll({payload}: FilterData): SagaIterator {
    try {
        const { sortBy, where, pageSize, currentPage } = payload;
        const defaultOrder = [{ key: 'id', order: 'ASC' }];
        const response = yield call(api.get, {url: `${BASE_URL}?where=${encodeURIComponent(where ? JSON.stringify(where) : "{}")}&order=${sortByToQueryParams(sortBy, defaultOrder)}&limit=${encodeURIComponent(pageSize ?? 20)}&page=${encodeURIComponent(currentPage ?? 1)}`});
        yield put(licensesApiResponseSuccess(LicenseActionTypes.GET_LICENSES, response.data));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(licensesApiResponseError(LicenseActionTypes.GET_LICENSES, error));
    }
}

/**
 * Fetch licenses registered in a tournament
 */
 function* getTournamentCompatibleLicenses({payload}: {payload: {id: number, registered: boolean, otherClubs: boolean}, type: string}): SagaIterator {
    try {
        const options = { sortBy: {}, pageSize: 999999, currentPage: 1, where: {
                tournament: {
                    id: payload.id,
                    registered: payload.registered,
                    otherClubs: payload.otherClubs,
                },
            }};
        const { sortBy, where, pageSize, currentPage } = options; // eslint-disable-line
        const response = yield call(api.get, {url: `${BASE_URL}?where=${encodeURIComponent(where ? JSON.stringify(where) : "{}")}&limit=${encodeURIComponent(pageSize ?? 20)}&page=${encodeURIComponent(currentPage ?? 1)}`});
        yield put(licensesApiResponseSuccess(LicenseActionTypes.GET_TOURNAMENT_LICENSES, response.data));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(licensesApiResponseError(LicenseActionTypes.GET_TOURNAMENT_LICENSES, error));
    }
}

/**
 * Fetch licenses - pass
 */
 function* getPassCompatibleLicenses({payload}: {payload: {id: number, registered: boolean, otherClubs: boolean}, type: string}): SagaIterator {
    try {
        const options = { sortBy: {}, pageSize: 999999, currentPage: 1, where: {
                pass: {
                    id: payload.id,
                    registered: payload.registered,
                    otherClubs: payload.otherClubs,
                },
            }};
        const { sortBy, where, pageSize, currentPage } = options; // eslint-disable-line
        const response = yield call(api.get, {url: `${BASE_URL}?where=${encodeURIComponent(where ? JSON.stringify(where) : "{}")}&limit=${encodeURIComponent(pageSize ?? 20)}&page=${encodeURIComponent(currentPage ?? 1)}`});
        yield put(licensesApiResponseSuccess(LicenseActionTypes.GET_TOURNAMENT_LICENSES, response.data));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(licensesApiResponseError(LicenseActionTypes.GET_TOURNAMENT_LICENSES, error));
    }
}

/**
 * Find given license
 */
 function* findOne({payload}: {payload: number, type: string}): SagaIterator {
    try {
        const response = yield call(api.get, {url: `${BASE_URL}${payload}`});
        yield put(licensesApiResponseSuccess(LicenseActionTypes.FIND_LICENSE, response.data));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(licensesApiResponseError(LicenseActionTypes.FIND_LICENSE, error));
    }
}

function* createLicense({payload}: LicenseData): SagaIterator {
    try {
		const { person, club, role, type, previousClub, cedido, addToShoppingCart } = payload;
		const createFields = { 
            personId: person.id, 
            clubId: club?.id, 
            roleId: role.id, 
            typeId: type.id, 
            previousClubId: previousClub?.id, 
            cedidoId: cedido?.id,
            addToShoppingCart,
        };
        const response = yield call(api.post, {url: BASE_URL, data: createFields});
        const created = response.data;
        yield put(getShoppingCarts());
        yield put(licensesApiResponseSuccess(LicenseActionTypes.CREATE_LICENSE, created));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(licensesApiResponseError(LicenseActionTypes.CREATE_LICENSE, error));
    }
}

function* updateLicense({payload}: LicenseData): SagaIterator {
    try {
		const { club, type, cedido } = payload;
		const updateFields = { 
            clubId: club?.id, 
            typeId: type.id, 
            cedidoId: cedido?.id,
        };
        const response = yield call(api.update, {url: `${BASE_URL}${payload.id ?? 0}`, data: updateFields});
        const updated = response.data;
        yield put(licensesApiResponseSuccess(LicenseActionTypes.UPDATE_LICENSE, updated));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(licensesApiResponseError(LicenseActionTypes.UPDATE_LICENSE, error));
    }
}

function* renewLicense({payload}: LicenseData): SagaIterator {
    try {
		const { type } = payload;
		const updateFields = { 
            typeId: type.id, 
        };
        const response = yield call(api.post, {url: `${BASE_URL}${payload.id ?? 0}/renew`, data: updateFields});
        yield put(getShoppingCarts());
        const updated = response.data;
        yield put(licensesApiResponseSuccess(LicenseActionTypes.RENEW_LICENSE, updated));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(licensesApiResponseError(LicenseActionTypes.RENEW_LICENSE, error));
    }
}

/**
 * Verify given license
 */
 function* verifyLicense({payload}: {payload: number, type: string}): SagaIterator {
    try {
        const response = yield call(api.post, {url: `${BASE_URL}${payload}/verify`});
        yield put(licensesApiResponseSuccess(LicenseActionTypes.VERIFY_LICENSE, response.data));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(licensesApiResponseError(LicenseActionTypes.VERIFY_LICENSE, error));
    }
}

/**
 * Transfer given license to user's club
 */
function* transferLicense({payload}: LicenseData): SagaIterator {
    try {
		const { type } = payload;
		const updateFields = { 
            typeId: type.id, 
        };
        const response = yield call(api.post, {url: `${BASE_URL}${payload.id ?? 0}/transfer`, data: updateFields});
        yield put(getShoppingCarts());
        yield put(licensesApiResponseSuccess(LicenseActionTypes.TRANSFER_LICENSE, response.data));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(licensesApiResponseError(LicenseActionTypes.TRANSFER_LICENSE, error));
    }
}

 function* getStatistics(): SagaIterator {
    try {
        const response = yield call(api.get, {url: `${BASE_URL}statistics`});
        yield put(licensesApiResponseSuccess(LicenseActionTypes.GET_STATISTICS, response.data));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(licensesApiResponseError(LicenseActionTypes.GET_STATISTICS, error));
    }
}


export function* watchGetAllLicenses() {
    yield takeEvery(LicenseActionTypes.GET_LICENSES, getAll);
}

export function* watchGetTournamentCompatibleLicenses() {
    yield takeEvery(LicenseActionTypes.GET_TOURNAMENT_LICENSES, getTournamentCompatibleLicenses);
}

export function* watchGetPassCompatibleLicenses() {
    yield takeEvery(LicenseActionTypes.GET_PASS_LICENSES, getPassCompatibleLicenses);
}

export function* watchFindLicense() {
    yield takeEvery(LicenseActionTypes.FIND_LICENSE, findOne);
}

export function* watchCreateLicense() {
    yield takeEvery(LicenseActionTypes.CREATE_LICENSE, createLicense);
}

export function* watchUpdateLicense() {
    yield takeEvery(LicenseActionTypes.UPDATE_LICENSE, updateLicense);
}

export function* watchRenewLicense() {
    yield takeEvery(LicenseActionTypes.RENEW_LICENSE, renewLicense);
}

export function* watchVerifyLicense() {
    yield takeEvery(LicenseActionTypes.VERIFY_LICENSE, verifyLicense);
}

export function* watchTransferLicense() {
    yield takeEvery(LicenseActionTypes.TRANSFER_LICENSE, transferLicense);
}

export function* watchGetStatistics() {
    yield takeEvery(LicenseActionTypes.GET_STATISTICS, getStatistics);
}

function* licensesSaga() {
    yield all([fork(watchGetAllLicenses), fork(watchGetPassCompatibleLicenses), fork(watchGetTournamentCompatibleLicenses), fork(watchFindLicense), fork(watchCreateLicense), fork(watchUpdateLicense), fork(watchRenewLicense), fork(watchVerifyLicense), fork(watchTransferLicense), fork(watchGetStatistics)]);
}

export default licensesSaga;
