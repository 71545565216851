import { all } from 'redux-saga/effects';

import authSaga from './auth/saga';
import layoutSaga from './layout/saga';
import personsSaga from './person/saga';
import usersSaga from './user/saga';
import clubsSaga from './club/saga';
import categoriesSaga from './category/saga';
import licenseRolesSaga from './licenseRole/saga';
import licenseTypesSaga from './licenseType/saga';
import licensesSaga from './license/saga';
import ageGroupsSaga from './ageGroup/saga';
import paymentsSaga from './payment/saga';
import productsSaga from './product/saga';
import ordersSaga from './order/saga';
import orderLinesSaga from './orderLine/saga';
import tournamentsSaga from './tournament/saga';
import passesSaga from './pass/saga';
import registrationsSaga from './registration/saga';
import teamsSaga from './team/saga';
import constantsSaga from './constant/saga';
import leaguesSaga from './league/saga';
import encountersSaga from './encounter/saga';
import matchesSaga from './match/saga';

export default function* rootSaga() {
    yield all([
        authSaga(),
        layoutSaga(), 
        personsSaga(), 
        usersSaga(), 
        clubsSaga(), 
        categoriesSaga(), 
        licenseRolesSaga(), 
        licenseTypesSaga(), 
        licensesSaga(), 
        ageGroupsSaga(),
        paymentsSaga(),
        productsSaga(),
        ordersSaga(),
        orderLinesSaga(),
        tournamentsSaga(),
        passesSaga(),
        registrationsSaga(),
        teamsSaga(),
        constantsSaga(),
        leaguesSaga(),
        encountersSaga(),
        matchesSaga(),
    ]);
}
