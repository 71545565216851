// constants
import { FilterType } from '../../helpers/api/apiCore';
import { EncounterActionTypes } from './constants';
import Encounter from './model';

export interface EncounterActionType {
    type:
        | EncounterActionTypes.API_RESPONSE_SUCCESS
        | EncounterActionTypes.API_RESPONSE_ERROR
        | EncounterActionTypes.GET_ENCOUNTERS
        | EncounterActionTypes.GET_DAY_ENCOUNTERS
        | EncounterActionTypes.FIND_ENCOUNTER
        | EncounterActionTypes.CREATE_ENCOUNTER
        | EncounterActionTypes.IMPORT_ENCOUNTERS
        | EncounterActionTypes.UPDATE_ENCOUNTER
        | EncounterActionTypes.ADD_ENCOUNTER_RESULTS
    payload: {} | string;
}

// common success
export const encountersApiResponseSuccess = (actionType: string, data: Encounter | Encounter[] | {} | []): EncounterActionType => ({
    type: EncounterActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const encountersApiResponseError = (actionType: string, error: string): EncounterActionType => ({
    type: EncounterActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getEncounters = (filter: FilterType): EncounterActionType => ({
    type: EncounterActionTypes.GET_ENCOUNTERS,
    payload: filter,
});

export const getDayEncounters = (filter: FilterType): EncounterActionType => ({
    type: EncounterActionTypes.GET_DAY_ENCOUNTERS,
    payload: filter,
});

export const findEncounter = (encounterId: number): EncounterActionType => ({
    type: EncounterActionTypes.FIND_ENCOUNTER,
    payload: encounterId,
});

export const createEncounter = (encounter: Encounter): EncounterActionType => ({
    type: EncounterActionTypes.CREATE_ENCOUNTER,
    payload: encounter,
});

export const updateEncounter = (encounter: Encounter): EncounterActionType => ({
    type: EncounterActionTypes.UPDATE_ENCOUNTER,
    payload: encounter,
});

export const updateEncounterResults = (encounter: Encounter): EncounterActionType => ({
    type: EncounterActionTypes.ADD_ENCOUNTER_RESULTS,
    payload: encounter,
});

export const importEncounters = (encounters: Encounter[]): EncounterActionType => ({
    type: EncounterActionTypes.IMPORT_ENCOUNTERS,
    payload: encounters,
});