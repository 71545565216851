import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { mwErrorHandling } from '../../helpers/errors';

// apicore
import { APICore } from '../../helpers/api/apiCore';

// actions
import { licenseTypesApiResponseSuccess, licenseTypesApiResponseError } from './actions';

// constants
import { LicenseTypeActionTypes } from './constants';

const api = new APICore();
const BASE_URL = '/license-types/';

/**
 * Get all licenseTypes
 */
function* getAll(): SagaIterator {
    try {
        const response = yield call(api.get, {url: BASE_URL});
        yield put(licenseTypesApiResponseSuccess(LicenseTypeActionTypes.GET_LICENSE_TYPES, response.data));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(licenseTypesApiResponseError(LicenseTypeActionTypes.GET_LICENSE_TYPES, error));
    }
}

export function* watchGetAllLicenseTypes() {
    yield takeEvery(LicenseTypeActionTypes.GET_LICENSE_TYPES, getAll);
}


function* licenseTypesSaga() {
    yield all([fork(watchGetAllLicenseTypes)]);
}

export default licenseTypesSaga;
