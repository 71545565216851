import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { mwErrorHandling } from '../../helpers/errors';

// apicore
import { APICore, FilterType } from '../../helpers/api/apiCore';
import { sortByToQueryParams } from '../../helpers/utils';

// actions
import { matchesApiResponseSuccess, matchesApiResponseError } from './actions';

// constants
import { MatchActionTypes } from './constants';
import Match from './model';

interface MatchData {
    payload: Match;
    type: string;
}

interface FilterData {
    payload: FilterType;
    type: string;
}

const api = new APICore();
const BASE_URL = '/matches/';



function* getAll({payload}: FilterData): SagaIterator {
    try {
        const { sortBy, where, pageSize, currentPage } = payload;
        const defaultOrder = [{ key: 'id', order: 'DESC' }];
        const response = yield call(api.get, {url:  `${BASE_URL}?where=${encodeURIComponent(where ? JSON.stringify(where) : "{}")}&order=${sortByToQueryParams(sortBy, defaultOrder)}&limit=${encodeURIComponent(pageSize ?? 20)}&page=${encodeURIComponent(currentPage ?? 1)}`});
        yield put(matchesApiResponseSuccess(MatchActionTypes.GET_MATCHES, response.data));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(matchesApiResponseError(MatchActionTypes.GET_MATCHES, error));
    }
}

function* findOne({payload}: {payload: number, type: string}): SagaIterator {
    try {
        const response = yield call(api.get, {url: `${BASE_URL}${payload}`});
        yield put(matchesApiResponseSuccess(MatchActionTypes.FIND_MATCH, response.data));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(matchesApiResponseError(MatchActionTypes.FIND_MATCH, error));
    }
}

function* createMatch({payload}: MatchData): SagaIterator {
    try {
		const { G1P1Points, G1P2Points, G2P1Points, G2P2Points, G3P1Points, G3P2Points, G4P1Points, G4P2Points, G5P1Points, G5P2Points, number, P1Char, P2Char, player11LicenseId, player12LicenseId, player21LicenseId, player22LicenseId, encounterId } = payload;
		const createFields = { G1P1Points, G1P2Points, G2P1Points, G2P2Points, G3P1Points, G3P2Points, G4P1Points, G4P2Points, G5P1Points, G5P2Points, number, P1Char, P2Char, player11LicenseId, player12LicenseId, player21LicenseId, player22LicenseId, encounterId };
        const response = yield call(api.post, {url: BASE_URL, data: createFields});
        const created = response.data;
        yield put(matchesApiResponseSuccess(MatchActionTypes.CREATE_MATCH, created));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(matchesApiResponseError(MatchActionTypes.CREATE_MATCH, error));
    }
}

function* updateMatch({payload}: MatchData): SagaIterator {
    try {
		const { G1P1Points, G1P2Points, G2P1Points, G2P2Points, G3P1Points, G3P2Points, G4P1Points, G4P2Points, G5P1Points, G5P2Points, P1Char, P2Char, player11LicenseId, player12LicenseId, player21LicenseId, player22LicenseId } = payload;
		const updateFields = { G1P1Points, G1P2Points, G2P1Points, G2P2Points, G3P1Points, G3P2Points, G4P1Points, G4P2Points, G5P1Points, G5P2Points, P1Char, P2Char, player11LicenseId, player12LicenseId, player21LicenseId, player22LicenseId };
        const response = yield call(api.update, {url: `${BASE_URL}${payload.id ?? 0}`, data: updateFields});
        const updated = response.data;
        yield put(matchesApiResponseSuccess(MatchActionTypes.UPDATE_MATCH, updated));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(matchesApiResponseError(MatchActionTypes.UPDATE_MATCH, error));
    }
}

export function* watchGetAllMatches() {
    yield takeEvery(MatchActionTypes.GET_MATCHES, getAll);
}

export function* watchFindMatch() {
    yield takeEvery(MatchActionTypes.FIND_MATCH, findOne);
}

export function* watchCreateMatch() {
    yield takeEvery(MatchActionTypes.CREATE_MATCH, createMatch);
}

export function* watchUpdateMatch() {
    yield takeEvery(MatchActionTypes.UPDATE_MATCH, updateMatch);
}

function* matchesSaga() {
    yield all([fork(watchGetAllMatches), fork(watchFindMatch), fork(watchCreateMatch), fork(watchUpdateMatch)]);
}

export default matchesSaga;
