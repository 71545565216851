// constants
import { PaginationResult } from '../../helpers/api/apiCore';
import { PassActionTypes } from './constants';
import Pass from './model';

const INIT_STATE = {
    passes: undefined,
    loading: false,
    requestStatus: 0,
};

interface PassActionType {
    type:
        | PassActionTypes.API_RESPONSE_SUCCESS
        | PassActionTypes.API_RESPONSE_ERROR
        | PassActionTypes.GET_PASSES
        | PassActionTypes.CREATE_PASS
        | PassActionTypes.UPDATE_PASS
        | PassActionTypes.DELETE_PASS
    payload: {
        actionType?: string;
        data?: Pass | {} | PaginationResult<Pass>;
        error?: string;
    };
}

interface State {
    passes?: PaginationResult<Pass>;
    loading?: boolean;
    requestStatus?: number;
}

const PassReducer = (state: State = INIT_STATE, action: PassActionType): any => {
    switch (action.type) {
        case PassActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case PassActionTypes.GET_PASSES: {
                    return {
                        ...state,
                        passes: action.payload.data,
                        loading: false,
                    };
                }
                case PassActionTypes.CREATE_PASS: {
                    return {
                        ...state,
                        loading: false,
                        requestStatus: 1,
                    };
                }
                case PassActionTypes.UPDATE_PASS: {
                    return {
                        ...state,
                        loading: false,
                        requestStatus: 1,
                    };
                }
                case PassActionTypes.DELETE_PASS: {
                    return {
                        ...state,
                        loading: false,
                        requestStatus: 1,
                    };
                }
                default:
                    return { ...state };
            }

        case PassActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                default:
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                        requestStatus: -1,
                    };
            }
        case PassActionTypes.GET_PASSES:
            return { ...state, loading: true, requestStatus: 0};
        case PassActionTypes.CREATE_PASS:
            return { ...state, loading: true, requestStatus: 0};
        case PassActionTypes.UPDATE_PASS:
            return { ...state, loading: true, requestStatus: 0};
        case PassActionTypes.DELETE_PASS:
            return { ...state, loading: true, requestStatus: 0};
        default:
            return { ...state };
    }
};

export default PassReducer;
