export enum LicenseActionTypes {
    API_RESPONSE_SUCCESS = '@@licenses/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@licenses/API_RESPONSE_ERROR',

    GET_LICENSES = '@@licenses/GET_LICENSES',
    GET_TOURNAMENT_LICENSES = '@@licenses/GET_TOURNAMENT_LICENSES',
    GET_PASS_LICENSES = '@@licenses/GET_PASS_LICENSES',
    FIND_LICENSE = '@@licenses/FIND_LICENSE',
    UPDATE_LICENSE = '@@licenses/UPDATE_LICENSE',
    DELETE_LICENSE = '@@licenses/DELETE_LICENSE',
    CREATE_LICENSE = '@@licenses/CREATE_LICENSE',

    VERIFY_LICENSE = '@@licenses/VERIFY_LICENSE',
    RENEW_LICENSE = '@@licenses/RENEW_LICENSE',
    TRANSFER_LICENSE = '@@licenses/TRANSFER_LICENSE',

    GET_STATISTICS = '@@licenses/GET_STATISTICS',

}
