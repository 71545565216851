import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { mwErrorHandling } from '../../helpers/errors';

// apicore
import { APICore } from '../../helpers/api/apiCore';

// actions
import { orderLinesApiResponseSuccess, orderLinesApiResponseError } from './actions';

// constants
import { OrderLineActionTypes } from './constants';
import OrderLine from './model';

interface OrderLineData {
    payload: OrderLine;
    type: string;
}

const api = new APICore();
const BASE_URL = '/order-lines/';

/**
 * Find given orderLine
 */
 function* findOne({payload}: {payload: number, type: string}): SagaIterator {
    try {
        const response = yield call(api.get, {url: `${BASE_URL}${payload}`});
        yield put(orderLinesApiResponseSuccess(OrderLineActionTypes.FIND_ORDERLINE, response.data));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(orderLinesApiResponseError(OrderLineActionTypes.FIND_ORDERLINE, error));
    }
}

function* createOrderLine({payload}: OrderLineData): SagaIterator {
    try {
		const { name, description, price, cancellable, blockDate, productId, orderId } = payload;
		const createFields = { name, description, price, cancellable, blockDate, productId, orderId };
        const response = yield call(api.post, {url: BASE_URL, data: createFields});
        const created = response.data;
        yield put(orderLinesApiResponseSuccess(OrderLineActionTypes.CREATE_ORDERLINE, created));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(orderLinesApiResponseError(OrderLineActionTypes.CREATE_ORDERLINE, error));
    }
}

function* updateOrderLine({payload}: OrderLineData): SagaIterator {
    try {
		const { price, name, description, cancellable, blockDate } = payload;
		const updateFields = { 
            price, 
            name, 
            description,
            cancellable,
            blockDate,
        };
        const response = yield call(api.update, {url: `${BASE_URL}${payload.id ?? 0}`, data: updateFields});
        const updated = response.data;
        yield put(orderLinesApiResponseSuccess(OrderLineActionTypes.UPDATE_ORDERLINE, updated));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(orderLinesApiResponseError(OrderLineActionTypes.UPDATE_ORDERLINE, error));
    }
}

function* deleteOrderLine({payload}: OrderLineData): SagaIterator {
    try {
        const response = yield call(api.delete, {url: `${BASE_URL}${payload.id ?? 0}`});
        const deleted = response.data;
        yield put(orderLinesApiResponseSuccess(OrderLineActionTypes.DELETE_ORDERLINE, deleted));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(orderLinesApiResponseError(OrderLineActionTypes.DELETE_ORDERLINE, error));
    }
}

export function* watchDeleteOrderLine() {
    yield takeEvery(OrderLineActionTypes.DELETE_ORDERLINE, deleteOrderLine);
}

export function* watchFindOrderLine() {
    yield takeEvery(OrderLineActionTypes.FIND_ORDERLINE, findOne);
}

export function* watchCreateOrderLine() {
    yield takeEvery(OrderLineActionTypes.CREATE_ORDERLINE, createOrderLine);
}

export function* watchUpdateOrderLine() {
    yield takeEvery(OrderLineActionTypes.UPDATE_ORDERLINE, updateOrderLine);
}

function* orderLinesSaga() {
    yield all([fork(watchDeleteOrderLine), fork(watchFindOrderLine), fork(watchCreateOrderLine), fork(watchUpdateOrderLine)]);
}

export default orderLinesSaga;
