import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { mwErrorHandling } from '../../helpers/errors';

// apicore
import { APICore } from '../../helpers/api/apiCore';

// actions
import { constantsApiResponseSuccess, constantsApiResponseError } from './actions';

// constants
import { ConstantActionTypes } from './constants';
import Constant from './model';

interface ConstantData {
    payload: Constant;
    type: string;
}

const api = new APICore();
const BASE_URL = '/constants/';

/**
 * Logout the user
 */
function* getAll(): SagaIterator {
    try {
        const response = yield call(api.get, {url: BASE_URL});
        yield put(constantsApiResponseSuccess(ConstantActionTypes.GET_CONSTANTS, response.data));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(constantsApiResponseError(ConstantActionTypes.GET_CONSTANTS, error));
    }
}

function* updateConstant({payload}: ConstantData): SagaIterator {
    try {
		const { itemValue } = payload;
		const updateFields = { itemValue };
        const response = yield call(api.update, {url: `${BASE_URL}${payload.id ?? 0}`, data: updateFields});
        const updated = response.data;
        yield put(constantsApiResponseSuccess(ConstantActionTypes.UPDATE_CONSTANT, updated));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(constantsApiResponseError(ConstantActionTypes.UPDATE_CONSTANT, error));
    }
}

export function* watchGetAllConstants() {
    yield takeEvery(ConstantActionTypes.GET_CONSTANTS, getAll);
}

export function* watchUpdateConstant() {
    yield takeEvery(ConstantActionTypes.UPDATE_CONSTANT, updateConstant);
}

function* constantsSaga() {
    yield all([fork(watchGetAllConstants), fork(watchUpdateConstant)]);
}

export default constantsSaga;
