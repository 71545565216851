import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { mwErrorHandling } from '../../helpers/errors';

// apicore
import { APICore, FilterType } from '../../helpers/api/apiCore';
import { sortByToQueryParams } from '../../helpers/utils';

// actions
import { teamsApiResponseSuccess, teamsApiResponseError } from './actions';

// constants
import { TeamActionTypes } from './constants';
import Team from './model';

interface TeamData {
    payload: Team;
    type: string;
}

interface FilterData {
    payload: FilterType;
    type: string;
}

const api = new APICore();
const BASE_URL = '/teams/';

/**
 * Logout the user
 */
function* getAll({payload}: FilterData): SagaIterator {
    try {
        const { sortBy, where, pageSize, currentPage } = payload;
        const defaultOrder = [{ key: 'id', order: 'ASC' }];
        const response = yield call(api.get, {url:  `${BASE_URL}?where=${encodeURIComponent(where ? JSON.stringify(where) : "{}")}&order=${sortByToQueryParams(sortBy, defaultOrder)}&limit=${encodeURIComponent(pageSize ?? 20)}&page=${encodeURIComponent(currentPage ?? 1)}`});
        yield put(teamsApiResponseSuccess(TeamActionTypes.GET_TEAMS, response.data));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(teamsApiResponseError(TeamActionTypes.GET_TEAMS, error));
    }
}

function* findOne({payload}: {payload: number, type: string}): SagaIterator {
    try {
        const response = yield call(api.get, {url: `${BASE_URL}${payload}`});
        yield put(teamsApiResponseSuccess(TeamActionTypes.FIND_TEAM, response.data));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(teamsApiResponseError(TeamActionTypes.FIND_TEAM, error));
    }
}

function* createTeam({payload}: TeamData): SagaIterator {
    try {
		const { name, groupId, settings, contactId, clubId, categoryId, sex } = payload;
		const createFields = { name, groupId, settings, contactId, clubId, categoryId, sex };
        const response = yield call(api.post, {url: BASE_URL, data: createFields});
        const created = response.data;
        yield put(teamsApiResponseSuccess(TeamActionTypes.CREATE_TEAM, created));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(teamsApiResponseError(TeamActionTypes.CREATE_TEAM, error));
    }
}

function* updateTeam({payload}: TeamData): SagaIterator {
    try {
		const { name, groupId, settings, contactId, clubId, categoryId, sex } = payload;        
		const updateFields = { name, groupId, settings, contactId, clubId, categoryId, sex };
        const response = yield call(api.update, {url: `${BASE_URL}${payload.id ?? 0}`, data: updateFields});
        const updated = response.data;
        yield put(teamsApiResponseSuccess(TeamActionTypes.UPDATE_TEAM, updated));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(teamsApiResponseError(TeamActionTypes.UPDATE_TEAM, error));
    }
}

function* registerTeam({payload}: TeamData): SagaIterator {
    try {
        const response = yield call(api.post, {url: `${BASE_URL}${payload.id ?? 0}/register`, data: {}});
        const updated = response.data;
        yield put(teamsApiResponseSuccess(TeamActionTypes.REGISTER_TEAM, updated));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(teamsApiResponseError(TeamActionTypes.REGISTER_TEAM, error));
    }
}

export function* watchGetAllTeams() {
    yield takeEvery(TeamActionTypes.GET_TEAMS, getAll);
}

export function* watchFindTeam() {
    yield takeEvery(TeamActionTypes.FIND_TEAM, findOne);
}

export function* watchCreateTeam() {
    yield takeEvery(TeamActionTypes.CREATE_TEAM, createTeam);
}

export function* watchUpdateTeam() {
    yield takeEvery(TeamActionTypes.UPDATE_TEAM, updateTeam);
}

export function* watchRegisterTeam() {
    yield takeEvery(TeamActionTypes.REGISTER_TEAM, registerTeam);
}

function* teamsSaga() {
    yield all([fork(watchGetAllTeams), fork(watchFindTeam), fork(watchCreateTeam), fork(watchUpdateTeam), fork(watchRegisterTeam)]);
}

export default teamsSaga;
