import Person from "../person/model";
import Club from "../club/model";

export enum UserRoles {
    ADMIN = 1,
    CLUB = 10,
    REFEREE = 11,
    TEMPLATE = 100
};

export default interface User {
    id?: number;
    username: string;
    password?: string;
    role: UserRoles;
    active: boolean;
    person: Person;
    club?: Club;
    accessToken?: string;
}