// constants
import { FilterType } from '../../helpers/api/apiCore';
import { RegistrationActionTypes } from './constants';
import Registration from './model';

export interface RegistrationActionType {
    type:
        | RegistrationActionTypes.API_RESPONSE_SUCCESS
        | RegistrationActionTypes.API_RESPONSE_ERROR
        | RegistrationActionTypes.CREATE_REGISTRATION
        | RegistrationActionTypes.UPDATE_REGISTRATION
        | RegistrationActionTypes.GET_REGISTRATIONS
        | RegistrationActionTypes.DELETE_REGISTRATION
        | RegistrationActionTypes.GET_AVAILABLE_REGISTRATIONS
        | RegistrationActionTypes.FIND_REGISTRATION
        | RegistrationActionTypes.RESET
    payload: {} | string;
}

// common success
export const registrationsApiResponseSuccess = (actionType: string, data: Registration | Registration[] | {} | []): RegistrationActionType => ({
    type: RegistrationActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const registrationsApiResponseError = (actionType: string, error: string): RegistrationActionType => ({
    type: RegistrationActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const createRegistration = (registration: Registration): RegistrationActionType => ({
    type: RegistrationActionTypes.CREATE_REGISTRATION,
    payload: registration,
});

export const updateRegistration = (registration: Registration): RegistrationActionType => ({
    type: RegistrationActionTypes.UPDATE_REGISTRATION,
    payload: registration,
});

export const deleteRegistration = (registration: Registration): RegistrationActionType => ({
    type: RegistrationActionTypes.DELETE_REGISTRATION,
    payload: registration,
});

export const getRegistrations = (filter: FilterType): RegistrationActionType => ({
    type: RegistrationActionTypes.GET_REGISTRATIONS,
    payload: filter,
});

export const getAvailableRegistrations = (tournamentId: number, passesIds: number[]): RegistrationActionType => ({
    type: RegistrationActionTypes.GET_AVAILABLE_REGISTRATIONS,
    payload: {tournamentId, passesIds},
});

export const findRegistration = (registrationId: number): RegistrationActionType => ({
    type: RegistrationActionTypes.FIND_REGISTRATION,
    payload: registrationId,
});

export const resetRegistrationsData = (): RegistrationActionType => ({
    type: RegistrationActionTypes.RESET,
    payload: {},
});