import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { mwErrorHandling } from '../../helpers/errors';

// apicore
import { APICore } from '../../helpers/api/apiCore';

// actions
import { personsApiResponseSuccess, personsApiResponseError } from './actions';

// constants
import { PersonActionTypes } from './constants';
import Person from './model';

interface PersonData {
    payload: Person;
    type: string;
}

const api = new APICore();
const BASE_URL = '/persons/';

/**
 * Logout the user
 */
function* getAll(): SagaIterator {
    try {
        const response = yield call(api.get, {url: BASE_URL});
        yield put(personsApiResponseSuccess(PersonActionTypes.GET_PERSONS, response.data));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(personsApiResponseError(PersonActionTypes.GET_PERSONS, error));
    }
}

function* createPerson({payload}: PersonData): SagaIterator {
    try {
		const { idNumber, firstName, lastName, sex, birthDate, nationality, address, city, province, phone, email, dniImg } = payload;
		const createFields = { idNumber, firstName, lastName, sex, birthDate, nationality, address, city, province, phone, email };
        const response = yield call(api.post, {url: BASE_URL, data: createFields});
        if (response?.data?.id) {
            const imgResponse =  yield call(api.postWithFile, {url: `${BASE_URL}${response.data.id}/dni`, data: {avatar: dniImg}});
            const created = imgResponse.data;
            yield put(personsApiResponseSuccess(PersonActionTypes.CREATE_PERSON, created));
        } 
        else yield put(personsApiResponseError(PersonActionTypes.CREATE_PERSON, "SAGA: Unknown error."));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(personsApiResponseError(PersonActionTypes.CREATE_PERSON, error));
    }
}

function* updatePerson({payload}: PersonData): SagaIterator {
    try {
		const { idNumber, firstName, lastName, sex, birthDate, nationality, address, city, province, phone, email, dniImg } = payload;
		const updateFields = { idNumber, firstName, lastName, sex, birthDate, nationality, address, city, province, phone, email };
        const response = yield call(api.update, {url: `${BASE_URL}${payload.id ?? 0}`, data: updateFields});
        if (typeof dniImg !== 'undefined' && typeof dniImg !== 'string') {
            const imgResponse =  yield call(api.postWithFile, {url: `${BASE_URL}${payload.id ?? 0}/dni`, data: {avatar: dniImg}});
            const updated = imgResponse.data;
            yield put(personsApiResponseSuccess(PersonActionTypes.UPDATE_PERSON, updated));
        } else yield put(personsApiResponseSuccess(PersonActionTypes.UPDATE_PERSON, response.data));
    } catch (error: any) {
        const mwHandled = yield call(mwErrorHandling, error);
        if (!mwHandled) yield put(personsApiResponseError(PersonActionTypes.UPDATE_PERSON, error));
    }
}

export function* watchGetAllPersons() {
    yield takeEvery(PersonActionTypes.GET_PERSONS, getAll);
}

export function* watchCreatePerson() {
    yield takeEvery(PersonActionTypes.CREATE_PERSON, createPerson);
}

export function* watchUpdatePerson() {
    yield takeEvery(PersonActionTypes.UPDATE_PERSON, updatePerson);
}

function* personsSaga() {
    yield all([fork(watchGetAllPersons), fork(watchCreatePerson), fork(watchUpdatePerson)]);
}

export default personsSaga;
